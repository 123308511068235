import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, required, ReferenceArrayField, Datagrid, TextField, ImageField, DateField, Labeled } from 'react-admin';
import { CardContent, Typography } from '@mui/material';

const CustomEmptyExperts = () => (
	<CardContent>
		<Typography variant="body2">
			No results found. Did the interview finish yet?
		</Typography>
	</CardContent>
);

const FormEntryEdit = () => (

	<Edit>
		<SimpleForm>
			<TextInput source="name" validate={required()} />
			<TextInput source="company" validate={required()} />
			<TextInput source="title" validate={required()} />
			<TextInput source="industry" validate={required()} />
			<TextInput source="location" validate={required()} />
			<TextInput source="country" validate={required()} />
			<TextInput source="email" validate={required()} />
			<ReferenceInput source="event_id" reference="events" >
				<AutocompleteInput optionText="name" validate={required()} fullWidth={false} sx={{ width: '30%' }} />
			</ReferenceInput>

			<Labeled label="Matched Experts">
				<ReferenceArrayField label="Experts" reference="experts" source="matched_experts">
					<Datagrid empty={<CustomEmptyExperts />}>
						<TextField source="name" />
						<ImageField source="image.src" />
					</Datagrid>
					
				</ReferenceArrayField>
			</Labeled>

			<Labeled label="Transcripts">
				<ReferenceArrayField reference="interviews" source="interview_ids">
					<Datagrid bulkActionButtons={false} rowClick="show" empty={<CustomEmptyExperts />}>
						<DateField label="Interview Time" source="createdAt" showDate showTime />
					</Datagrid>
				</ReferenceArrayField>
			</Labeled>

		</SimpleForm>
	</Edit>
);

export default FormEntryEdit;
